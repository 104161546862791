<template>
  <div>
    <div class="ag-block__image" :class="{ 'ag-block__image--full': !showTitle && !showCaption }">
      <a v-if="block.item.url" :href="block.item.url" rel="nofollow noindex" target="_blank" @click.prevent="onClick(block)">
        <img :src="block.item.image.full_url" />
      </a>
      <div v-else-if="block.item.image_display === 'full'">
        <img :src="block.item.image.full_url" />
      </div>
      <img v-else :src="block.item.image.full_url" />

      <div v-if="hasImageMeta || block.downloadable" class="ag-block__meta">
        <Popover v-if="hasImageMeta" class="relative">
          <PopoverButton class="ag-block__image-meta-button">
            <Button icon="circle-info" variant="link" :ui="{ base: 'p-0 h-10 w-10 text-paragraph/50 hover:bg-primary hover:text-white' }" />
          </PopoverButton>

          <PopoverPanel class="ag-block__image-meta">
            <div v-if="block.item.source">
              <a v-if="block.item.source_url" :href="block.item.source_url" target="_blank" rel="nofollow noindex"
                >{{ block.item.source }} <Icon icon="arrow-up-right-from-square" prefix="far" />
              </a>
              <span v-else>{{ block.item.source }}</span>
            </div>
            <div v-if="block.item.license">
              <a v-if="block.item.license_url" :href="block.item.license_url" target="_blank" rel="nofollow noindex"
                >{{ block.item.license }} <Icon icon="arrow-up-right-from-square" prefix="far" />
              </a>
              <span v-else>{{ block.item.license }}</span>
            </div>
            <div v-if="block.item.attribution">{{ block.item.attribution }}</div>
            <div v-if="block.item.modifications">{{ block.item.modifications }}</div>
          </PopoverPanel>
        </Popover>

        <Button
          v-if="block.downloadable"
          as="a"
          :href="block.item.download_url"
          prepend-icon="cloud-arrow-down"
          :ui="{ base: 'text-paragraph/50 hover:bg-primary hover:text-white' }"
          rel="nofollow noindex"
          @click="trackClick({ collection: $route.meta.personalisationHashid, content: block.hashid })"
          >Download Photo</Button
        >
      </div>
    </div>

    <h3 v-if="showTitle" class="ag-block__title">{{ block.item.title }}</h3>
    <p v-if="showCaption" v-html="$markdown(block.item.caption)" class="ag-block__description" />
  </div>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

const props = defineProps<{
  block: ImageBlock;
  customisations: Customisations;
}>();

// Image
const { content_synopsis_enabled, content_title_enabled } = toRefs(props.customisations);

const showTitle = computed(() => content_title_enabled.value && props.block.item.title);
const showCaption = computed(() => content_synopsis_enabled.value && props.block.item.caption);

// Image Meta
const hasImageMeta = props.block.item.source || props.block.item.license || props.block.item.attribution || props.block.item.modifications;

// Click Handler
const { onClick } = useBlock();
const { trackClick } = useAnalytics();
</script>

<style lang="postcss">
.ag-block--image {
  .ag-block__image {
    @apply relative;

    img {
      @apply h-96 w-full rounded-lg object-cover;
    }

    &--full {
      @apply h-full;

      img {
        @apply h-full;
      }
    }

    &-meta {
      @apply absolute bottom-full right-0 min-w-max max-w-[12.5rem] origin-top rounded-md bg-slate-900/75 px-6 py-4 text-xs text-white transition-opacity;

      a {
        @apply text-white hover:text-white hover:underline !important;

        .fa-arrow-up-right-from-square {
          @apply ml-1 text-xs;
        }
      }
    }
  }

  .ag-block__title {
    @apply mt-4;
  }

  .ag-block__description {
    @apply mt-2;
  }

  .ag-block__meta {
    @apply absolute bottom-3 right-3 z-10 flex items-center rounded-md bg-white/50;
  }

  &.ag-row-span-2 {
    .ag-block__image {
      img {
        @apply lg:h-[48rem];
      }

      &--full {
        img {
          @apply lg:h-full;
        }
      }
    }
  }
}
</style>
